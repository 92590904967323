import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';

import client_page_img from './images/header_image/client_img_header.jpg';
import g1 from './images/gallery/10.webp';
import g2 from './images/gallery/11.webp';
import g3 from './images/gallery/12.webp';
import g4 from './images/gallery/13.webp';

import g5 from './images/gallery/1.webp';
import g6 from './images/gallery/2.webp';
import g7 from './images/gallery/3.webp';
import g8 from './images/gallery/4.webp';

const useStyle_gallery = makeStyles({

    img_style:{
        width: '100%',
        height: 'auto',
        borderStyle: 'outset',
        padding: 5,
        borderRadius: 10,
    },
    title_style:{
        textAlign: 'center',
        lineHeight: 5,
        textTransform: 'uppercase',
    },
    image_responsive:{
        width:'100%',
        height:'auto',
    }
})

function Gallery(){

    const custom_gallery_style = useStyle_gallery();
    return(
        <React.Fragment>
        <img src={client_page_img} className={custom_gallery_style.image_responsive}></img>
            <Typography variant="h5" className={custom_gallery_style.title_style}>Our Gallery</Typography>
            <Container maxWidth="lg">
                <Grid container spacing={3} style={{textAlign:'center'}}>
                    <Grid item sm={3}>
                        <img src={g1} className={custom_gallery_style.img_style}></img>
                    </Grid>
                    <Grid item sm={3}>
                        <img src={g2} className={custom_gallery_style.img_style}></img>
                    </Grid>
                    <Grid item sm={3}>
                        <img src={g3} className={custom_gallery_style.img_style}></img>
                    </Grid>
                    <Grid item sm={3}>
                        <img src={g4} className={custom_gallery_style.img_style}></img>
                    </Grid>
                </Grid>
                <br></br>
                <Grid spacing={3} container style={{textAlign:'center'}}>
                    <Grid item sm={3}>
                        <img src={g5} className={custom_gallery_style.img_style}></img>
                    </Grid>
                    <Grid item sm={3}>
                        <img src={g6} className={custom_gallery_style.img_style}></img>
                    </Grid>
                    <Grid item sm={3}>
                        <img src={g7} className={custom_gallery_style.img_style}></img>
                    </Grid>
                    <Grid item sm={3}>
                        <img src={g8} className={custom_gallery_style.img_style}></img>
                    </Grid>
                </Grid>
                <br></br>
            </Container>
            </React.Fragment>
    );
}

export default Gallery;