import React from 'react';
import { Navbar } from 'react-bootstrap';

import img_1 from './images/carsousel/updated/1.webp';
import img_2 from './images/carsousel/updated/2.webp'
import img_3 from './images/carsousel/updated/11.jpg';
import img_4 from './images/carsousel/updated/4.webp';
import img_5 from './images/carsousel/2.webp';

function Carousel(){
    return(
        <React.Fragment>
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={img_1} class="d-block w-100" alt="..."></img>
    </div>
    <div class="carousel-item">
      <img src={img_2} class="d-block w-100" alt="..."></img>
    </div>
    <div class="carousel-item">
      <img src={img_3} class="d-block w-100" alt="..."></img>
    </div>
    <div class="carousel-item">
      <img src={img_4} class="d-block w-100" alt="..."></img>
    </div>
    <div class="carousel-item">
      <img src={img_5} class="d-block w-100" alt="..."></img>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
        </React.Fragment>
    );
}

export default Carousel;