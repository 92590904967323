import React from 'react';
import site_logo from './images/logo.jpg';
import Carousel from './Carousel';
import Profile from './Profile';
import Gallery from './Gallery';
import Services from './Services';
import { makeStyles } from '@material-ui/core/styles';
import './style.css';


import { AppBar, Container, Grid, Toolbar, Typography,} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MenuIcon from '@material-ui/icons/Menu';




import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Link } from '@material-ui/core';
import clsx from 'clsx';




import { BrowserRouter, Route } from 'react-router-dom';
import Our_Profile from './our_profile';

const useApp_style = makeStyles({
    copyright:{
        fontFamily:'Montserrat',
        paddingTop:10,
        fontSize:12,
        textTransform: 'uppercase',
        paddingBottom:10,
      },
      list: {
        width: 250,
      },
      fullList: {
        width: 'auto',
      },
})

function App1(){

    const customApp_style = useApp_style();
     const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(customApp_style.list, {
        [customApp_style.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>



      <a href="/" id="drawer_style">HOME</a>
      <a href="/Our-profile" id="drawer_style">PROFILE</a>
      <a href="/Services" id="drawer_style">SERVICES</a>
      <a href="/Gallery" id="drawer_style">GALLERY</a>
      <a to="/Contact Us" id="drawer_style">CONTACT US</a>

      
    </div>
  );


    return(
        <BrowserRouter>
       
        <React.Fragment>
       
           <AppBar style={{backgroundColor:"white"}}>
               <Toolbar style={{padding:0}}>
           
                <Grid item md={3} sm={3}>
                    <img src={site_logo}></img>
                </Grid>
                <Grid item md={9} sm={9}>
                    <Grid container id="icon_nav">

                        <Grid item md={9} sm={9}>
                            <Typography style={{lineHeight:'50px', textAlign:'right'}} id="call">
                            CALL US: 044 46483107
                            
                            </Typography>
                        
                        </Grid>
                        <Grid item md={3} sm={3}>
                        <ul  style={{textAlign:'right' , paddingRight:'25px'}}>
                                
                                <li><a href="#"><FacebookIcon id="icons"/></a></li>
                                <li><a href="#"><TwitterIcon id="icons"/></a></li>
                                <li><a href="#"><InstagramIcon id="icons"/></a></li>
                                <li><a href="#"><YouTubeIcon id="icons"/></a></li>
                                <li><a href="#"><LinkedInIcon id="icons"/></a></li>
                               
                            </ul>
                        </Grid>
                        
                        <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <MenuIcon id="menu" onClick={toggleDrawer(anchor, true)}>{anchor}</MenuIcon>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
                    </Grid>

                    
                        <Grid item md={12} sm={12} id="nav">
                            <ul>
                            <li><a href="/">HOME</a></li>
                             <li><a href="/Our-profile">PROFILE</a></li>
                             <li><a href="/Services">SERVICE</a></li>
                            
                           
                            <li><a href="/Gallery">GALLERY</a></li>
                         
                        <li><a to="/Contact">CONTACT US</a></li>
                            </ul>
                           
                        </Grid>
                </Grid>
           
           </Toolbar>
           </AppBar>
           
           
               <div>
               <Route exact path="/" component={Profile} />
               <Route path="/Our-profile" component={Our_Profile} />
               <Route path="/Services" component={Services} />
               <Route path="/Gallery" component={Gallery} />
                
               </div>
        






           <Grid container>
      <Grid item style={{textAlign:'center',backgroundColor:'#B05D59',opacity:'0.7'}} md={4} sm={12} xs={12}>
        <img src={site_logo}></img>
      </Grid>
      <Grid item md={8} sm={12} xs={12} style={{backgroundColor:'#B05D59',opacity:0.9}}>
        <div style={{paddingTop:20,textAlign:'center'}}>
        <Typography id="fl">  
          <a href="/">HOME</a>
        </Typography>
        
        
        <Typography id="fl">
        <a href="/Gallery">PROFILE</a>
        </Typography>
        <Typography id="fl">
        <a href="Services">SERVICE</a>
        </Typography>
        <Typography id="fl">
        <a href="Our-profile">GALLERY</a>
        </Typography>
        <Typography id="fl">
        <a href="">CONTACT US</a>
        </Typography>
        
        </div>
     
      </Grid>
      
    </Grid>
    <Grid container>
      <Grid item md={12} sm={12} xs={12} style={{textAlign:'center',backgroundColor:'#B05D59'}}>
        <Typography style={{color:'white'}} className={customApp_style.copyright}>Copyright © 2021 HRUDAY INFRA Pvt. Ltd | All rights reserved</Typography>
      </Grid>
    </Grid>
        </React.Fragment>
        </BrowserRouter>
    );
}

export default App1;