import { Container, Typography, Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './style.css';

import service_img_1 from './images/Services/updated/service1.jpeg';
import service_img_2 from './images/Services/updated/service2.jpeg'; 
import service_img_3 from './images/Services/updated/service3.jpeg';
import service_img_4 from './images/Services/updated/service4.jpeg';

import client_page_img from './images/header_image/client_img_header.jpg';

const useStyles3 = makeStyles({
    service_styling:{
        textAlign:'center',
        paddingTop:30,
        fontFamily:'Montserrat',
        textTransform:'uppercase',
    },
    header_img_style:{
        width:'100%',
        height:'auto',  
    }
   
})

function Services(){

    const customStyle3 = useStyles3();
    return(
        <React.Fragment>

    
    
       <img src={client_page_img} className={customStyle3.header_img_style}></img>
       
            
     
    
       <Container maxWidth="md">
           <Typography variant="h4" className={customStyle3.service_styling}>
               Services We Offer
           </Typography>  
       </Container>
            <br></br>
        <Container maxWidth="lg">
            <Grid container style={{textAlign:'center'}}>
                <Grid item md={3} sm={6} xs={12}>
                
                  <div id="s1">
                  <img src={service_img_1}></img>
                   <p id="p_style">Water Supply</p>
                  </div>
                    
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <div id="s1">
                    <img src={service_img_2}></img>
                    <p id="p_style">Road Construction</p>
                    </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                <div id="s1">
                  <img src={service_img_3}></img>
                   <p id="p_style">Industrial and Buildings</p>
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                <div id="s1">
                    <img src={service_img_4}></img>
                    <p id="p_style"> Irrgatition</p>
                    </div>
                </Grid>
            </Grid>
<br></br>
           
        </Container>
       
       </React.Fragment>
    );
}

export default Services;