import React from 'react';
import { Container, Grid, Paper, Typography } from '@material-ui/core';
import profile_image from './images/our_profile/industry.jpg';
import { makeStyles } from '@material-ui/core/styles';
import { auto } from '@popperjs/core';
import './style.css';

import client_page_img from './images/header_image/client_img_header.jpg';


const useStyle2 = makeStyles({
    image_style:{
        width: 1920,
        height: '400px',
    },
    content_styling:{
        fontSize:25,
        fontWeight: 400,
        padding: "10px 0",
        fontFamily: 'Montserrat',
    },
    paper_styling:{
        height: 300,
        boxShadow: "0 1px 30px 1px black",
    },
    profile_styling:{
        textAlign:'center',
        paddingTop:30,
        paddingBottom: 30,
        fontFamily: 'Montserrat',
    },
    header_image_style:{
        width:'100%',
        height:'auto',
    }
})

function Our_Profile(){

    const customStyle2 = useStyle2();
    return(
        <React.Fragment>
         
              
                    
         <img src={client_page_img} className={customStyle2.header_image_style}></img>
                    
               <div>
               <Container id="paper">
                    
                        <Grid container style={{textAlign:'center'}}>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography className={customStyle2.content_styling}>1992</Typography>
                            <Typography className={customStyle2.content_styling}>YEAR FOUNDED</Typography>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography className={customStyle2.content_styling}>45</Typography>
                            <Typography className={customStyle2.content_styling}>PROFESSIONALS</Typography>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography className={customStyle2.content_styling}>1665</Typography>
                            <Typography className={customStyle2.content_styling}>KM HIGHWAY</Typography>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography className={customStyle2.content_styling}>40</Typography>
                            <Typography className={customStyle2.content_styling}>AWARDS WINNER</Typography>
                        </Grid>
                        </Grid>
                    
                </Container>
                   </div> 
        
                <Container>
                    <Typography variant="h3" className={customStyle2.profile_styling}>
                        OUR PEOPLE
                    </Typography>
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                            <p style={{fontSize:'20px',fontWeight:'370'}}>
                        We are committed to high standards of excellence in all our activities
and have thus been engaged in nation building. Hruday Infra Solutions was born out of BBCL Builders and attained the Private Limited Company status in 2014. Our company is promoted by Vummidi Ananth (Managing Director), a first-generation entrepreneur, assisted by a team of techno-commercial professionals.
                            </p>
                        
                        </Grid>
                    
                       
                    </Grid>
                    <br></br>
                </Container>
                
        </React.Fragment>
    );

   
}

export default Our_Profile;