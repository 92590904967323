import React from 'react';
import { Container, Typography, Grid } from '@material-ui/core';
import './style.css';

import { Navbar } from 'react-bootstrap';

import img_1_carousel from './images/carsousel/updated/13.jpg';
import img_2_carousel from './images/carsousel/updated/12.jpg'
import img_3_carousel from  './images/carsousel/updated/11.jpg';
import img_4_carousel from './images/carsousel/updated/4.webp';
import img_5_carousel from './images/carsousel/2.webp';

import profile_image from './images/profile/profile.jpg';

import { makeStyles } from '@material-ui/core/styles';
import img_1 from './images/icons/road.svg';
import img_2 from './images/icons/build.svg';
import img_3 from './images/icons/inbox_1.svg';
import img_4 from './images/icons/certificate_1.svg';
import img_5 from './images/texture/texture2.jpg';

import galleryimg_1 from './images/gallery/7.webp';
import galleryimg_2 from './images/Services/ind_road.jpg';
import galleryimg_3 from './images/carsousel/updated/2.webp';
import galleryimg_4 from './images/carsousel/updated/6.jpg';
import galleryimg_5 from './images/gallery/11.webp';

const useStyle = makeStyles({
    profile_image_style:{
        width:'100%',
        height:'auto',
    },
    profile_container_style:{
        paddingTop:25,
        paddingBottom: 25,
        paddingLeft: 25,
        paddingRight: 25,
    },
    icon_profile_container_style:{
        paddingTop:50,
        paddingBottom: 50,
        paddingLeft: 50,
        paddingRight: 50,
    },
    profile_text_style:{
        fontFamily:'Source Sans Pro',
    },  
    image_styling:{
        width: 60,

    },

    icon_center_styling:{
        textAlign:'center',
    },
    texture_styling:{
        width:'100%',
        height:'auto',
    },
    our_achievement_styles:{
        textAlign:'center'
    },

    our_achievement_grid_styles:{
        paddingTop:50,
        paddingBottom: 50,
        paddingLeft: 50,
        paddingRight: 50,
    },
    bio_achievement_grid_container_styles:{
        paddingTop:30,
    },
    bio_achievement_grid_styles:{
        textAlign:'center',
    },
    bio_styling:{
        textAlign:'center',
    },
   details_styling:{
       textAlign:'center',
   },
   img_1_style:{
       width:'100%',
       height:'auto',
   },
   img_2_style:{
       width:'100%',
       height:'auto',
   }

    

})

function Profile(){

    const customStyle = useStyle();
    return(
        <React.Fragment>


<div style={{marginTop:'100px'}}>
    
<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={img_1_carousel} class="d-block w-100" alt="..." style={{height:'600px'}}></img>
      <p id="carousel_titles">Bridge Construction</p>
    </div>
    <div class="carousel-item">
      <img src={img_2_carousel} class="d-block w-100" alt="..." style={{height:'600px'}}></img>
      <p id="carousel_titles">Construction</p>
    </div>
    <div class="carousel-item">
      <img src={img_3_carousel} class="d-block w-100" alt="..." style={{height:'600px'}}></img>
      <p id="carousel_titles">Water Supply</p>
    </div>
    <div class="carousel-item">
      <img src={img_4_carousel} class="d-block w-100" alt="..." style={{height:'600px'}}></img>
      <p id="carousel_titles">Road Construction</p>
    </div>
    <div class="carousel-item">
      <img src={img_5_carousel} class="d-block w-100" alt="..." style={{height:'600px'}}></img>
      <p id="carousel_titles">Irrigation</p>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

</div>












            <div className={customStyle.profile_container_style}>
            <Grid container spacing={6}>
           
           <Grid item md={6} sm={6} xs={12}>
               <Typography id="profile_heading" variant="h5">OUR PROFILE</Typography>
               <p id="profile_txt">Hruday Infra and Resource Solutions Pvt Ltd. One of India’s fastest growing niche infrastructure players.
               <br></br><br></br>
               Hruday Infra is strengthening competencies with an asset light business model and strong relationships with equipment rental agencies, the company is able to ensure faster mobilization of equipment, quicker project liquidation and lower costs.
                </p>
           </Grid>
           <Grid item md={6} sm={6} xs={12} spacing={6}>
               <img src={profile_image} className={customStyle.profile_image_style}></img>
           </Grid>
           
      </Grid>
            </div>
   

            <div className={customStyle.icon_profile_container_style}>
         <Grid container>
             <Grid item md={3} sm={3} xs={12} className={customStyle.icon_center_styling}>
                 
                 <img src={img_1} className={customStyle.image_styling}></img>
                 <Typography variant="h6" className={customStyle.heading_styling_2}>
                     Projects
                 </Typography>
                 <Typography variant="body2" className={customStyle.family_style} style={{paddingTop:10}} id="a1">
                 Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                 </Typography>
             </Grid>
             <Grid item md={3} sm={3} xs={12} className={customStyle.icon_center_styling}>
                 
                 <img src={img_2} className={customStyle.image_styling}></img>
                 <Typography variant="h6" className={customStyle.heading_styling_2}>
                     Infrastructure
                 </Typography>
                 <Typography variant="body2" className={customStyle.family_style} id="a1" style={{paddingTop:10}}>
                 Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                 </Typography>
             </Grid>
             <Grid item md={3} sm={3} xs={12} className={customStyle.icon_center_styling}>
                 
                 <img src={img_3} className={customStyle.image_styling}></img>
                 <Typography variant="h6" className={customStyle.heading_styling_2}>
                     E-Catelog
                 </Typography>
                 <Typography variant="body2" className={customStyle.family_style} id="a1" style={{paddingTop:10}}>
                 Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                 and services.
                 </Typography>
             </Grid>
             <Grid item md={3} sm={3} xs={12} className={customStyle.icon_center_styling}>
                 
                 <img src={img_4} className={customStyle.image_styling}></img>
                 <Typography variant="h6" className={customStyle.heading_styling_2}>
                     Certificate
                 </Typography>
                 <Typography variant="body2" className={customStyle.family_style} id="a1" style={{paddingTop:10}}>
                 Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                 </Typography>
             </Grid>
         </Grid>
</div>
         <Grid container>
                 <Grid item md={12} sm={12} xs={12}>
                     <img src={img_5} className={customStyle.texture_styling}></img>
                 </Grid>
             </Grid>






             <div className={customStyle.profile_container_style}>
                 <Grid container>
                     <Grid item md={12} sm={12} xs={12}  className={customStyle.our_achievement_styles}>
         
                     <Typography variant="h4">
                         OUR ACHIEVEMENTS
                     </Typography>
        
                     </Grid>
 
                     
                         <Grid container className={customStyle.bio_achievement_grid_container_styles}>
                             <Grid item md={3} sm={3} xs={12} className={customStyle.bio_achievement_grid_styles}>
                                 <Typography variant="h3" className={customStyle.bio_styling}>
                                     1994
                                 </Typography>
                                 <Typography variant="body1" className={customStyle.details_styling}>
                                     YearFounded
                                 </Typography>
                             </Grid>
                             <Grid item md={3} sm={3} xs={12}>
                             <Typography variant="h3" className={customStyle.bio_styling}>
                                     45
                             </Typography>
                             <Typography variant="body1" className={customStyle.details_styling}>
                             PROFESSIONALS
                             </Typography>
                             </Grid>
                             <Grid item md={3} sm={3} xs={12}>
                             <Typography variant="h3" className={customStyle.bio_styling}>
                                 1655
                             </Typography>
                             <Typography variant="body1" className={customStyle.details_styling}>
                                 KM HIGHWAY
                             </Typography>
                             </Grid>
                             <Grid item md={3} sm={3} xs={12}>
                             <Typography variant="h3" className={customStyle.bio_styling}>
                                 40
                             </Typography>
                             <Typography variant="body1" className={customStyle.details_styling}>
                             ESTIMEED CLIENTS
                             </Typography>
                             </Grid>
                         </Grid>
                     
 
                 </Grid>

</div>

                 <Grid container className={customStyle.gallery_styling}>
                     <Grid item md={6} sm={6} xs={12}>
                         <img src={galleryimg_1} className={customStyle.img_1_style} id="animation"></img>
                         
                     </Grid>
                   
                         <Grid item md={3} sm={3} xs={12}>
                             <img src={galleryimg_2} className={customStyle.img_2_style} id="animation"></img>
                             <img src={galleryimg_3} className={customStyle.img_2_style} id="animation"></img>
                         </Grid>
                         <Grid item md={3} sm={3} xs={12}>
                             <img src={galleryimg_4} className={customStyle.img_2_style} id="animation"></img>
                             <img src={galleryimg_5} className={customStyle.img_2_style} id="animation"></img>
                         </Grid>
                     
                 </Grid>
             
                 

        </React.Fragment>
        
    );
}

export default Profile;